export default {
  core: {
    history: "История",
    ok: "ОК",
    cancel: "Отмена",
    read_more: "Показать больше",
    close_modal: "Закрыть"
  },
  coupon: {
    back: "Назад",
    cancel: "Отмена",
    redeem: "Продолжить",
    input: {
      code: "Промокод"
    },
    modal: {
      default: {
        title: "Промокод",
        text: "Вы можете оплатить депозит в размере {deposit_value} с помощью действительного промокода. Если стоимость промокода недостаточна, вы можете оплатить оставшуюся сумму другим способом оплаты."
      },
      insufficient: {
        title: "Недостаточная стоимость промокода",
        text: "У промокода стоимость {coupon_amount}. Вы все равно можете воспользоваться этим промокодом и затем внести дополнительную оплату в размере {deposit_balance}."
      },
      invalid: {
        title: "Недействительный промокод",
        text: "К сожалению, введенный промокод недействителен. Пожалуйста, используйте действительный промокод или выберите другой способ оплаты."
      },
      remaining: {
        title: "Оплата оставшейся суммы",
        item: "{payment_date} Оплата в размере {payment_amount} успешно выполнена",
        text: "Пожалуйста, выберите способ оплаты для оплаты оставшейся суммы в размере {deposit_balance}."
      }
    }
  },
  page: {
    gallery: {
      image: "Изображение",
      video: "Видео",
      pano: "360°",
      plan: "Планиметрия",
      gallery: "Изображение галереи",
      mute_audio: "Выключить звук",
      unmute_audio: "Включить звук"
    },
    whatsapp: {
      scan_code: "Отсканировать QR-код",
      contact_over: "Связаться через приложение WhatsApp",
      whatsapp_web: "WhatsApp Web",
      whatsapp_web_contact: "Связаться через веб-приложение"
    },
    mode: {
      configuration: {
        message: "Целевая страница находится в режиме конфигурации"
      },
      versioned: {
        message_default: "Старая версия",
        message: "Версия от {date}"
      }
    },
    success: {
      messageSuccessful: "Ваше сообщение успешно отправлено"
    },
    errors: {
      messageIsEmpty: "Пожалуйста, добавьте сообщение",
      no_hash: {
        title: "Произошла ошибка",
        text: "Пожалуйста, попробуйте еще раз позже"
      },
      payment: {
        title: "Не удалось обработать платеж",
        text: "Пожалуйста, попробуйте еще раз позже",
        hint: "Сообщение об ошибке: ",
        btn_retry: "Повторить"
      },
      checkin: {
        tech_issue: {
          title: "К сожалению, сервис онлайн-регистрации недоступен",
          text: "Если вы хотите зарегистрироваться онлайн, пожалуйста, свяжитесь с нами"
        }
      },
      book_after_arrival_date: {
        title: "Предложение больше не доступно",
        text: "К сожалению, выбранное предложение больше не доступно. Пожалуйста, свяжитесь с нами для получения нового предложения"
      }
    },
    nav: {
      offer: "Предложение",
      highlights: "Основные моменты",
      info_services: "Информация и услуги",
      about: "О нас",
      contact: "Контакт",
      terms_of_use: "Условия использования",
      privacy_policy: "Политика конфиденциальности"
    },
    content: {
      today_reception: "Сегодня для вас на ресепшене:"
    },
    highlights: {},
    footer: {
      partners: "Партнеры",
      contact: "Свяжитесь с нами",
      menu: "Навигация",
      info: "Информация"
    },
    account: {
      vat: "НДС",
      cin: "CIN (Codice Identificativo Nazionale)"
    },
    placeholder: {
      whatsapp: "У меня есть вопрос по предложению #{offer}"
    }
  },
  entity: {
    best_offer: "Наилучшее предложение для вас",
    details: "Подробности",
    insurance: {
      text: "С нашей страховкой от отмены поездки вы будете финансово защищены в случае неожиданной невозможности путешествия.",
      ctabtn: "СТРАХОВКА ПУТЕШЕСТВИЯ",
      title: "Страховка путешествия"
    },
    selected: "ВЫБРАНО",
    select: "Выбрать",
    highlight: "Особенность",
    highlights_header: "Основные моменты",
    personal_info_header: "Личная информация",
    personal_info: "Личная информация",
    show_details: "Показать детали",
    hide_details: "Скрыть детали",
    variant: "Вариант",
    variant_x: "Вариант {x}",
    nights_x: "нет ночей | 1 ночь | {x} ночи | {x} ночей",
    room_x: "Номер {x}",
    arrival: "Прибытие",
    arrival_daparture: "Прибытие/Отъезд",
    persons_treatment: "Количество человек/Процедуры",
    room_category: "Категория номера",
    departure: "Отъезд",
    offer: "Предложение",
    entity_of: " {n} из {count}",
    deposit_needed: "Требуется депозит {amount}",
    no_deposit_needed: "Депозит не требуется",
    valid_till: "Предложение действительно до:",
    valid_till_expired:
      "Это предложение больше не действительно, пожалуйста, свяжитесь с нами, если вы хотите получить другое предложение.",
    cancel_policy: {
      title: "Политика отмены",
      show: "Показать политику отмены",
      free_cancellation: "Бесплатная отмена",
      free_cancellation_until: "Бесплатная отмена до"
    },
    mealplans: {
      HB: "Полупансион",
      WS: "-",
      BB: "Ночлег и завтрак",
      FB: "Полный пансион",
      AI: "Все включено",
      RO: "Только ночлег"
    },
    room: {
      rooms: "Проживание | Проживания",
      persons: "- | 1 человек | {count} человека | {count} человек",
      child_pl: "1 ребенок | {count} детей",
      adult_pl: "нет взрослых | 1 взрослый | {count} взрослых",
      traveler: "Количество человек",
      treatment: "Процедура",
      period: "Пребывание",
      info: "Информация",
      description: "Описание",
      room_change_on: "Изменение номера на"
    },
    treatment: {
      close: "Закрыть",
      booked_spa_header: "Забронированные услуги SPA",
      date: "Дата",
      time: "Время",
      time_definition: "В {time}",
      period: "Продолжительность",
      show_details: "Показать детали",
      time_slug: "мин",
      package: {
        applied_spa: "Услуги",
        description: "Описание",
        total_amount: "Общая сумма"
      },
      item: {
        description: "Описание",
        title: "Процедура",
        quantity: "Количество",
        price: "Цена",
        time: "Продолжительность",
        guest_name: "Гость",
        date: "Дата",
        date_time: "Время"
      }
    },
    price: {
      description: "Описание",
      description_short: "Описание",
      quantity: "Количество",
      discount: "Скидка",
      amount: "Сумма",
      taxes_included: "Туристический налог включен",
      show_price_details: "Показать детали цены и политику отмены",
      show_price_details_short: "Показать детали цены",
      hide_price_details: "Скрыть детали цены",
      deposit_balance: "Задаток",
      price: "Цена",
      total_price: "Общая стоимость",
      deposit: "Задаток",
      deposit_amount: "Сумма задатка",
      balance: "Оставшийся баланс"
    },
    payment: {
      select_method: "Пожалуйста, выберите способ оплаты",
      payment_success:
        "Оплата в размере {amount} была успешно произведена через {gateway_name} {payment_created_data} в {payment_created_time}.",
      authorized_bank_transfer_payment:
        "{gateway_name} был выбран в качестве способа оплаты на сумму {amount}.",
      success_bank_transfer_payment:
        "Перевод в размере {amount} был успешно завершен через {gateway_name} {payment_changed_data} в {payment_changed_time}.",
      default_payment_success:
        "Оплата в размере {amount} была успешно завершена {payment_created_data}.",
      default_payment_authorized:
        "Оплата в размере {amount} была зарегистрирована.",
      no_payment_methods:
        "К сожалению, эту предложение нельзя забронировать, так как не указаны способы оплаты. Пожалуйста, свяжитесь с отелем для получения дополнительной информации.",
      preauthorized_payment:
        "Платеж в размере {amount} был успешно авторизован {payment_created_data} в {payment_created_time} с помощью {gateway_name}.",
      methods: {
        credit_card: "Кредитная карта",
        credit_card_hint:
          "Кредитная карта: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        wire_transfere: "Банковский перевод",
        wire_transfere_hint:
          "Банковский перевод: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        paypal: "PayPal",
        paypal_hint:
          "PayPal: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur."
      },
      secure_checkout: "Безопасный оформление заказа",
      secure_checkout_hint:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat condimentum."
    },
    request: {
      thank_you: {
        header: "Большое спасибо за ваш запрос",
        text: "Ваше бронирование было успешно передано, и мы подтвердим его как можно скорее. Если у вас возникнут дополнительные вопросы, не стесняйтесь связаться с нами.",
        button: "Закрыть"
      }
    },
    buttons: {
      book: "Забронировать",
      details: "Подробности",
      coupon_apply: "Активировать",
      reserve: "Зарезервировать"
    },
    expiration_time: "до {time} по Центральноевропейскому времени (CET)"
  },
  personal_details: "Персональные данные",
  checkin: {
    room_x_of: "Номер",
    next: "Далее",
    select: {
      country: "Выберите страну",
      date: "Выберите дату",
      time: "Выберите время"
    },
    allocate_room: "Выделить комнату",
    thank_you: {
      title: "Онлайн-регистрация успешно завершена!",
      text: "Ваше бронирование успешно отправлено, и мы подтвердим его в кратчайшие сроки. Если у вас возникнут вопросы, не стесняйтесь обращаться к нам."
    },
    title: "Онлайн-регистрация",
    guest_1: "Основной гость",
    guest_2: "Вторичный гость",
    choose_room: "Выберите комнату"
  },
  promocode: {
    apply: "Применить",
    remove: "Удалить",
    label: "Промокод",
    added: "Промокод был применен",
    not_valid: "Промокод недействителен",
    deleted: "Промокод был удален"
  },
  form: {
    errors: {
      selectPayMethod: "Пожалуйста, выберите способ оплаты"
    },
    error_occured: "Произошла ошибка. Пожалуйста, проверьте ваши данные",
    payment: {
      error: {
        title: "Произошла ошибка",
        text: "Пожалуйста, попробуйте позже"
      }
    },
    doctypes: {
      ID_CARD: "Удостоверение личности",
      DRIVERS_LICENSE: "Водительское удостоверение",
      PASSPORT: "Паспорт"
    },
    labels: {
      additional_info: "Дополнительная информация",
      document_info: "Информация о документе",
      birth_info: "Информация о рождении",
      address_info: "Адрес",
      contact_info: "Имя и контактная информация",
      assign_room: "Распределение комнат",
      other_info: "Другое"
    },
    region: "Область",
    upload: "Загрузить",
    country_iso: "Страна",
    mobilephone: "Мобильный",
    birth_date: "Дата рождения",
    birth_place: "Место рождения",
    birth_municipality: "Муниципалитет рождения",
    birth_country_iso: "Страна рождения",
    birth_region: "Область рождения",
    fiscal_code: "Номер налогового идентификатора",
    nationality_iso: "Национальность",
    document_type: "Тип документа",
    document_number: "Номер документа",
    document_valid_from: "Дата выдачи",
    document_valid_to: "Действительно до",
    document_issuing_country_iso: "Страна выдачи",
    document_issuing_city: "Орган, выдавший документ",
    arrival: "Прибытие",
    checkin_time: "Время прибытия",
    license_plate: "Номер лицензии",
    driver_name: "Имя водителя",
    car_brand: "Марка автомобиля",
    notes: "Дополнительные запросы",
    terms_short: "Принять политику конфиденциальности",
    action_send: "Отправить данные",
    gender_please_select: "Пожалуйста, выберите пол",
    back: "Назад",
    close: "Закрыть",
    select_paymethod: "Выберите способ оплаты",
    checkout: "Оформить заказ",
    salutation: "Обращение",
    title_options: {
      m: "Г-н",
      f: "Г-жа"
    },
    name: "Имя",
    firstname: "Имя",
    lastname: "Фамилия",
    street: "Улица",
    zip: "Почтовый индекс",
    city: "Город",
    municipality: "Муниципалитет",
    country: "Страна",
    email: "E-Mail",
    email_hint: "Lorem ipsum dolor sit amet consectetur.",
    phone: "Телефон",
    cellphone: "Мобильный",
    phone_hint: "Lorem ipsum dolor sit amet consectetur.",
    contact: "Адрес",
    hint_booking: "{link_booking} Loren ipsum sit dolor",
    easy_reservation: "Необязывающее бронирование",
    terms: "Я принимаю политику конфиденциальности и общие условия.",
    checkin_terms: "Я принимаю [политику конфиденциальности].",
    newsletter:
      "Я хочу получать регулярные уведомления о предложениях и новостях.",
    check_terms: "Пожалуйста, примите условия и политику"
  },
  dict: {
    for: "за"
  },
  booking: {
    default: {
      title: "Большое спасибо за ваш запрос!",
      text: "Ваш запрос был успешно отправлен, и мы обработаем его как можно скорее. Если у вас возникнут дополнительные вопросы, пожалуйста, не стесняйтесь связаться с нами."
    },
    offer: {
      title: "Большое спасибо за ваш запрос на бронирование!",
      text: "Ваш запрос на бронирование был успешно отправлен, и мы подтвердим бронирование как можно скорее. Если у вас возникнут дополнительные вопросы, пожалуйста, не стесняйтесь связаться с нами."
    },
    order: {
      title: "Большое спасибо за ваше бронирование!",
      text: "Ваше бронирование было успешно передано, и мы подтвердим его как можно скорее. Если у вас возникнут дополнительные вопросы, пожалуйста, не стесняйтесь связаться с нами."
    }
  },
  chat_window: {
    new_messages: "У вас есть непрочитанные сообщения!",
    message_empty: "Нет сообщений",
    conversation_started: "Начало беседы:",
    type_message: "Введите сообщение",
    search: "Поиск",
    previewMode:
      "В режиме предварительного просмотра нельзя отправлять сообщения в чате."
  },
  checkin_error_message:
    "Внимание, данная кампания не назначена гостю. Сначала необходимо добавить гостя, чтобы можно было заполнить онлайн-форму регистрации.",
  checkout: {
    price_table: "Таблица цен"
  },
  chat_email_from: "от",
  upselling: {
    category: {
      category_title: "Категория",
      without_category: "Дополнительные услуги без категории"
    },
    service: {
      service_title: "Дополнительные услуги",
      add_service: "К выбору",
      selected_service: "выбранный",
      change_service: "Редактировать",
      add_to_cart_service: "Добавить",
      calc_modes: {
        MODE_PER_DAY: "за ночь",
        MODE_PER_PERSON: "на человека",
        MODE_PER_DAY_AND_PERSON: "за ночь и человека",
        MODE_PER_PRODUCT_UNIT: "за единицу размещения",
        MODE_PER_LIVING_UNIT_AND_DAY: "за единицу размещения и ночь",
        MODE_PER_ITEM: "единая ставка"
      },
      calc_info:
        'Указанная цена является общей суммой типа расчета "{calc_mode}" данной услуги.',
      preselected_service_hotel: "Забронировано {n}x отелем",
      preselected_service_customer: "Забронировано {n}x гостем",
      show_more_services: "Показать все услуги",
      hide_services: "Скрыть все услуги"
    },
    service_price_table: {
      service: "Услуга",
      quantity: "Количество",
      price: "Цена",
      amount: "Сумма",
      description: "Описание"
    }
  }
};
