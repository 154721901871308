export default {
  core: {
    history: "History",
    ok: "OK",
    cancel: "Cancel",
    read_more: "Show more",
    close_modal: "Close"
  },
  coupon: {
    back: "Back",
    cancel: "Cancel",
    redeem: "Continue",
    input: {
      code: "Coupon code"
    },
    modal: {
      default: {
        title: "Coupon code",
        text: "You can pay the deposit of {deposit_value} with a valid voucher. If the voucher value is not high enough, you can pay the remaining amount with another payment."
      },
      insufficient: {
        title: "Coupon value not sufficient",
        text: "The coupon has a value of {coupon_amount}. You can redeem this voucher anyway and then make balance payment of {deposit_balance}."
      },
      invalid: {
        title: "Coupon not valid",
        text: "Unfortunately, the inserted coupon is not valid. Please use a valid coupon or select a different payment method."
      },
      remaining: {
        title: "Balance payment",
        item: "{payment_date} Payment of {payment_amount} successfully completed",
        text: "Please select a payment method to make the balance payment of {deposit_balance}."
      }
    }
  },
  page: {
    gallery: {
      image: "Image",
      video: "Video",
      pano: "360°",
      plan: "Floor plan",
      gallery: "Gallery image",
      mute_audio: "Mute",
      unmute_audio: "Unmute"
    },
    whatsapp: {
      scan_code: "Scan QR code",
      contact_over: "Contact via WhatsApp App",
      whatsapp_web: "WhatsApp Web",
      whatsapp_web_contact: "Contact via web app"
    },
    mode: {
      configuration: {
        message: "Landing page is in configuration mode"
      },
      versioned: {
        message_default: "Old version",
        message: "Version from {date}"
      }
    },
    success: {
      messageSuccessful: "Your message was sent successfully"
    },
    errors: {
      messageIsEmpty: "Please add a message",
      no_hash: {
        title: "An error has occured",
        text: "Please try again later"
      },
      payment: {
        title: "The payment could not be processed",
        text: "Please try again later",
        hint: "Error message: ",
        btn_retry: "Retry"
      },
      checkin: {
        tech_issue: {
          title: "Sorry, the online check-in service is not available",
          text: "Please contact us if you would like to do an online check-in"
        }
      },
      book_after_arrival_date: {
        title: "Offer no longer available",
        text: "Sorry, the selected offer is no longer available. Please contact us for a new offer."
      }
    },
    nav: {
      offer: "Offer",
      highlights: "Highlights",
      info_services: "Info & Services",
      about: "About",
      contact: "Contact",
      terms_of_use: "Terms of use",
      privacy_policy: "Privacy Policy"
    },
    content: {
      today_reception: "Today for you at the reception:"
    },
    highlights: {},
    footer: {
      partners: "Partners",
      contact: "Contact us",
      menu: "Navigation",
      info: "Information"
    },
    account: {
      vat: "VAT",
      cin: "CIN (Codice Identificativo Nazionale)"
    },
    placeholder: {
      whatsapp: "I have a question for an offer #{offer}"
    }
  },
  entity: {
    best_offer: "Our best offer for you",
    details: "Details",
    insurance: {
      text: "With our trip cancellation protection insurance, you are financially protected should you be unexpectedly unable to travel.",
      ctabtn: "TRAVEL INSURANCE",
      title: "Travel insurance"
    },
    selected: "SELECTED",
    select: "Select",
    highlight: "Highlight",
    highlights_header: "Highlights",
    personal_info_header: "Personal info",
    personal_info: "Personal info",
    show_details: "Show details",
    hide_details: "Hide details",
    variant: "Variant",
    variant_x: "Variant {x}",
    nights_x: "1 night|{x} Nights",
    room_x: "Room {x}",
    arrival: "Arrival",
    arrival_daparture: "Arrival/Departure",
    persons_treatment: "Persons/Treatment",
    room_category: "Room category",
    departure: "Departure",
    offer: "Offer",
    entity_of: " {n} of {count}",
    deposit_needed: "Deposit of {amount} is required",
    no_deposit_needed: "No deposit needed",
    valid_till: "Offer is valid till:",
    valid_till_expired:
      "This offer is no longer valid, please contact us if you wish to receive another offer.",
    cancel_policy: {
      title: "Cancellation policy",
      show: "Show cancellation policy",
      free_cancellation: "Free cancellation",
      free_cancellation_until: "Free cancellation before"
    },
    mealplans: {
      HB: "Halfboard",
      WS: "-",
      BB: "Bed&Breakfast",
      FB: "Fullboard",
      AI: "All Inclusive",
      RO: "Only overnight"
    },
    room: {
      rooms: "Accommodation | Accommodations",
      persons: "1 person |{count} people",
      child_pl: "1 Child|{count} Children",
      adult_pl: "1 Adult|{count} Adults",
      traveler: "People",
      treatment: "Treatment",
      period: "Stay",
      info: "Information",
      description: "Description",
      room_change_on: "Room change on"
    },
    treatment: {
      close: "Close",
      booked_spa_header: "Booked Spa services",
      date: "Date",
      time: "Time",
      time_definition: "At {time}",
      period: "Duration",
      show_details: "Show details",
      time_slug: "min",
      package: {
        applied_spa: "Services",
        description: "Description",
        total_amount: "Total amount"
      },
      item: {
        description: "Description",
        title: "Treatment",
        quantity: "Quantity",
        price: "Price",
        time: "Duration",
        guest_name: "Guest",
        date: "Date",
        date_time: "Time"
      }
    },
    price: {
      description: "Description",
      description_short: "Descr.",
      quantity: "Quantity",
      discount: "Discount",
      amount: "Amount",
      taxes_included: "Tourist tax included",
      show_price_details: "Show price details and cancellation policy",
      show_price_details_short: "Show price details",
      hide_price_details: "Hide price details",
      deposit_balance: "Outstanding deposit",
      price: "Price",
      total_price: "Total price",
      deposit: "Deposit",
      deposit_amount: "Deposit amount",
      balance: "Outstanding balance"
    },
    payment: {
      select_method: "Please select a payment method",
      payment_success:
        "The payment of {amount} was successfully made by {gateway_name} on {payment_created_data} at {payment_created_time}.",
      authorized_bank_transfer_payment:
        "{gateway_name} was selected as the payment method for the amount of {amount}.",
      success_bank_transfer_payment:
        "The transfer of {amount} was successfully completed by {gateway_name} on {payment_changed_data} at {payment_changed_time}.",
      default_payment_success:
        "The payment of {amount} was successfully completed on {payment_created_data}.",
      default_payment_authorized: "The payment of {amount} was registered.",
      no_payment_methods:
        "Unfortunately, this offer cannot be booked because there are no payment methods defined. Please contact the hotel for further information.",
      preauthorized_payment:
        "Payment of {amount} was successfully autorized by {gateway_name} on {payment_created_data} at {payment_created_time}.",
      methods: {
        credit_card: "Credit card",
        credit_card_hint:
          "Kreditkarte: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        wire_transfere: "Wire transfere",
        wire_transfere_hint:
          "Überweisung: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        paypal: "PayPal",
        paypal_hint:
          "PayPal: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur."
      },
      secure_checkout: "Secure Checkout",
      secure_checkout_hint:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat condimentum."
    },
    request: {
      thank_you: {
        header: "Thank you very much for your request",
        text: "Your booking has been successfully transmitted and we will confirm it as soon as possible. Please do not hesitate to contact us if you have any further questions.",
        button: "Close"
      }
    },
    buttons: {
      book: "Book",
      details: "Details",
      coupon_apply: "Redeem",
      reserve: "Reserve"
    },
    expiration_time: "until {time} CET"
  },
  personal_details: "Personal details",
  checkin: {
    room_x_of: "Room",
    next: "Next",
    select: {
      country: "Select country",
      date: "Select date",
      time: "Select time"
    },
    allocate_room: "Allocate room",
    thank_you: {
      title: "Online check-in successfully completed!",
      text: "Your booking has been successfully transmitted and we will confirm it as soon as possible. Please do not hesitate to contact us if you have any further questions."
    },
    title: "Online Check-In",
    guest_1: "Primary guest",
    guest_2: "Secondary guest",
    choose_room: "Choose a room"
  },
  promocode: {
    apply: "Apply",
    remove: "Remove",
    label: "Promotional code",
    added: "Discount code has been applied",
    not_valid: "The discount code is not valid",
    deleted: "Discount code has been removed"
  },
  form: {
    errors: {
      selectPayMethod: "Please select your payment method"
    },
    error_occured: "An error is occured. Please check your data",
    payment: {
      error: {
        title: "An error has occurred",
        text: "Please try again later"
      }
    },
    doctypes: {
      ID_CARD: "ID Card",
      DRIVERS_LICENSE: "Drivers license",
      PASSPORT: "Passport"
    },
    labels: {
      additional_info: "Additional info",
      document_info: "Document information",
      birth_info: "Birth information",
      address_info: "Address",
      contact_info: "Name and contact information",
      assign_room: "Room allocation",
      other_info: "Other"
    },
    region: "Province",
    upload: "Upload",
    country_iso: "Country",
    mobilephone: "Mobile",
    birth_date: "Birth date",
    birth_place: "Birth place",
    birth_municipality: "Birth municipality",
    birth_country_iso: "Birth country",
    birth_region: "Birth province",
    fiscal_code: "Tax number",
    nationality_iso: "Nationality",
    document_type: "Type of document",
    document_number: "Document number",
    document_valid_from: "Issuing date",
    document_valid_to: "Valid till",
    document_issuing_country_iso: "Issuing country",
    document_issuing_city: "Issuing authority",
    arrival: "Arrival",
    checkin_time: "Arrival time",
    license_plate: "License plate",
    driver_name: "Driver's name",
    car_brand: "Car brand",
    notes: "Further requests",
    terms_short: "Accept privacy policy",
    action_send: "Submit data",
    gender_please_select: "Please select gender",
    back: "Back",
    close: "Close",
    select_paymethod: "Select your payment method",
    checkout: "Checkout",
    salutation: "Title",
    title_options: {
      m: "Mr",
      f: "Mrs"
    },
    name: "Name",
    firstname: "Firstname",
    lastname: "Lastname",
    street: "Street",
    zip: "ZIP",
    city: "City",
    municipality: "Municipality",
    country: "Country",
    email: "E-Mail",
    email_hint: "Lorem ipsum dolor sit amet consectetur.",
    phone: "Phone",
    cellphone: "Mobile",
    phone_hint: "Lorem ipsum dolor sit amet consectetur.",
    contact: "Address",
    hint_booking: "{link_booking} Loren ipsum sit dolor",
    //your_request: 'Your request',
    easy_reservation: "Non-binding reservation",
    terms: "I accept the privacy policy and general terms and conditions.",
    checkin_terms: "I accept the [privacy policy].",
    newsletter:
      "I want to receive regular notifications about offers and news.",
    check_terms: "Please accept terms and policy"
  },
  dict: {
    for: "per"
  },
  booking: {
    default: {
      title: "Thank you very much for your request!",
      text: "Your request has been successfully submitted and we will process it as soon as possible. Please do not hesitate to contact us if you have any further questions."
    },
    offer: {
      title: "Thank you very much for your reservation request!",
      text: "Your booking request has been successfully submitted and we will confirm the booking as soon as possible. Please do not hesitate to contact us if you have any further questions."
    },
    order: {
      title: "Thank you very much for your reservation!",
      text: "Your booking has been successfully transmitted and we will confirm it as soon as possible. Please do not hesitate to contact us if you have any further questions."
    }
  },
  chat_window: {
    new_messages: "You have unread messages!",
    message_empty: "No messages",
    conversation_started: "Conversation started on:",
    type_message: "Type message",
    search: "Search",
    previewMode: "No chat messages can be sent in the preview mode."
  },
  checkin_error_message:
    "Attention, this campaign is not assigned to a guest. A guest must first be added so that the online check-in form can be filled out.",
  checkout: {
    price_table: "Price table"
  },
  chat_email_from: "from",
  upselling: {
    category: {
      category_title: "Category",
      without_category: "Additional services without category"
    },
    service: {
      service_title: "Additional services",
      add_service: "Selection",
      selected_service: "selected",
      change_service: "Edit",
      add_to_cart_service: "Add",
      calc_modes: {
        MODE_PER_DAY: "per night",
        MODE_PER_PERSON: "per person",
        MODE_PER_DAY_AND_PERSON: "per night & person",
        MODE_PER_PRODUCT_UNIT: "per Accommodation unit",
        MODE_PER_LIVING_UNIT_AND_DAY: "per Accommodation unit & night",
        MODE_PER_ITEM: "flat-rate"
      },
      calc_info:
        'The indicated price is the total amount of the calculation type "{calc_mode}" of this service.',
      preselected_service_hotel: "Booked {n}x by hotel",
      preselected_service_customer: "Booked {n}x by the guest",
      show_more_services: "Show all services",
      hide_services: "Hide all services"
    },
    service_price_table: {
      service: "Service",
      quantity: "Quantity",
      price: "Price",
      amount: "Amount",
      description: "Description"
    }
  }
};
