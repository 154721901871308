<template>
  <div class="footer-wrapper">
    <b-container>
      <div id="footer" class="footer">
        <template v-if="configData.sections.overwrite != 2">
          <b-row class="pt-3">
            <b-col cols="12" md="3">
              <template v-if="configData.map.image && configData.map.url">
                <a :href="configData.map.url" target="_blank">
                  <b-img
                    v-if="configData.map.image"
                    class="img-fluid"
                    :src="configData.map.image + '?d=300x200'"
                  />
                </a>
              </template>
              <template v-else>
                <b-img
                  v-if="configData.account.logo && configData.account.logo.data"
                  :src="configData.account.logo.data.url + '?d=150x150'"
                  class="hotel-logo img-fluid"
                />
              </template>
              <div v-if="configData.account.name" class="mt-3">
                &copy; {{ configData.account.name }}
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <h5 v-if="configData.account.name" class="footer-heading">
                {{ configData.account.name }}
              </h5>
              <div
                class="footer-content"
                v-if="
                  configData.account.address && configData.account.address.data
                "
              >
                <div>
                  {{ getVal(configData.account.address.data.street) }}
                </div>
                <div>
                  {{ getVal(configData.account.address.data.city_zip) }}
                  {{ getVal(configData.account.address.data.city_name) }}
                </div>
                <div
                  v-if="
                    configData.account.fiscal && configData.account.fiscal.data
                  "
                >
                  {{ $t("page.account.vat") }}:
                  {{ getVal(configData.account.fiscal.data.vat_number) }}
                  <div v-if="getVal(configData.account.fiscal.data.cin_number)">
                    {{ $t("page.account.cin") }}:
                    <br
                      v-if="
                        getVal(
                          configData.account.fiscal.data.cin_number
                        ).indexOf(',') > 0
                      "
                    />
                    {{
                      getVal(
                        configData.account.fiscal.data.cin_number
                      ).replaceAll(",", ", ")
                    }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
              v-if="
                configData.design_config.navigation &&
                configData.design_config.navigation.filter(
                  (m) => m.type == 'secondary'
                ).length > 0
              "
            >
              <h5
                v-html="
                  placeholder(
                    '%label.footer.custom_menu%',
                    $t('page.footer.menu')
                  )
                "
                class="footer-heading"
              ></h5>
              <div class="footer-content">
                <div
                  v-for="(page, k) in configData.design_config.navigation
                    .filter((m) => m.type == 'secondary')
                    .sort((a, b) => a.sequence - b.sequence)"
                  :key="k"
                >
                  <a
                    :href="page.translations[$i18n.locale].url"
                    target="_blank"
                    v-html="placeholder(page.placeholder, page.id)"
                  ></a>
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              v-if="
                configData.account.contact && configData.account.contact.data
              "
            >
              <h5
                v-html="
                  placeholder(
                    '%label.footer.contact%',
                    $t('page.footer.contact')
                  )
                "
                class="footer-heading"
              ></h5>
              <div class="flex-column social-media footer-content d-flex">
                <div
                  class="email"
                  v-if="
                    configData.account.contact.data.email ||
                    configData.account.contact.data.phone
                  "
                >
                  <div>
                    <a :href="'tel:' + configData.account.contact.data.phone">
                      {{ configData.account.contact.data.phone }}
                    </a>
                  </div>
                  <div>
                    <a
                      :href="
                        'mailto:' +
                        configData.account.contact.data.email +
                        '?Subject=' +
                        placeholder('%label.offer.title%', $t('entity.offer')) +
                        ' ' +
                        configData.entity.slug +
                        ' ' +
                        $t('chat_email_from') +
                        ' ' +
                        configData.account.name
                      "
                    >
                      {{ configData.account.contact.data.email }}
                    </a>
                  </div>
                </div>
                <div
                  class="social-media"
                  v-if="configData.design_config.footer"
                >
                  <a
                    target="_blank"
                    v-if="configData.design_config.footer.facebook"
                    :href="configData.account.contact.data.facebook"
                  >
                    <span class="mdi mdi-facebook"></span>
                  </a>
                  <a
                    target="_blank"
                    v-if="configData.design_config.footer.twitter"
                    :href="configData.account.contact.data.twitter"
                  >
                    <span class="mdi mdi-twitter"></span>
                  </a>
                  <a
                    target="_blank"
                    v-if="configData.design_config.footer.instagram"
                    :href="configData.account.contact.data.instagram"
                  >
                    <span class="mdi mdi-instagram"></span>
                  </a>
                  <a
                    target="_blank"
                    v-if="configData.design_config.footer.pinterest"
                    :href="configData.account.contact.data.pinterest"
                  >
                    <span class="mdi mdi-pinterest"></span>
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </div>
    </b-container>
    <template
      v-if="
        configData.sections.overwrite == 2 ||
        ('block' in configData.elements && configData.elements.block.length > 0)
      "
    >
      <custom-element
        class="py-3"
        v-for="customFooter in configData.elements.block"
        :key="customFooter.id"
        :content="customFooter"
      ></custom-element>
    </template>
    <b-container class="pb-2">
      <b-row>
        <b-col class="text-right powered-by">
          <a href="https://www.yanovis.com/" target="_blank">
            <img
              src="@/assets/powered_by_yanovis_positive.png"
              alt="Powered by Yanovis"
              title="Visions become software"
            />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import CustomElement from "@/components/CustomElement";

export default {
  mixins: [lpMixins],
  components: { CustomElement },
  name: "Footer",
  props: ["configData", "page"]
};
</script>

<style scoped></style>
